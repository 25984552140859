<template>
  <v-container
    id="login"
    fluid
    tag="section"
  >
    <v-dialog
      v-model="carga"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="text-h4 font-weight-light white--text">
          INICIANDO SESIÓN
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      absolute
      touchless
      width="100%"
      :src="imgFondo"
      permanent
    >
      <br><br><br><br><br>
      <v-card
        color="white"
        height="60%"
        width="65%"
        class="mx-auto"
      >
        <iframe
          width="100%"
          height="80%"
          src="https://lookerstudio.google.com/embed/reporting/610236ff-5272-4c44-b488-457bca2ae9ec/page/HsfHD"
          frameborder="0"
          style="border:0"
          allowfullscreen
        />
      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
  export default {
    name: 'Login',
    components: {
    },

    data: () => ({
      usuario: '',
      clave: '',
      carga: false,
      sedeCorteData: '',
      regla: [v => !!v || 'El campo es requerido'],
      imgFondo: '',
      imgLogo: '',
      error: false,
      textoError: '',
    }),
    created () {
      this.imgFondo = localStorage.getItem('imgFondo')
      this.imgLogo = localStorage.getItem('imgLogo')
    },
    mounted () {
      this.$store.commit('SET_VALOR', true)
    },
  }
</script>
